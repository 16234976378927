import { useRef, useState } from 'react';

import Input from '@/components/inputs/input';
import { clearUserInfo, setUserInfo, useAppDispatch } from '@/store';
import { useRouter } from 'next/router';

import { PopupNoti } from '@/components/screen/common/PopupNoti';
import { handleEncode, SECRET_KEY } from '@/helper/encode';
import useSavaSessionKey from '@/hooks/useSavaSessionKey';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import { sendLog } from '@/services/sendLog';
import { clearUserTempInfo, setUserTempInfo } from '@/store/userTemp';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import useContentGlobal from '@/hooks/useContentGlobal';

const initErrorMessage = {
  account: "",
  password: "",
}

const FormSignIn = () => {
  const { control, watch } = useForm();
  const [errorField, setErrorField] = useState(initErrorMessage);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();
  const formRef = useRef<HTMLFormElement | any>(null);
  const { session_key } = useSavaSessionKey();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { getValueByKey } = useContentGlobal();

  const handleSetErrorMessage = (message: string) => (
    listError: any,
    field: string,
    value: string | boolean
  ) => {
    if (!value) return { ...listError, [field]: message };
    return { ...listError, [field]: "" }
  }

  const checkEmptyField = handleSetErrorMessage(getValueByKey(["Message", "Error", "Empty_Field"]));

  const handleCheckValueForm = () => {
    const account = formRef.current?.[0]?.value;
    const password = formRef.current?.[1]?.value;

    let _errorField = errorField;
    _errorField = checkEmptyField(_errorField, "account", account);
    _errorField = checkEmptyField(_errorField, "password", password);

    return setErrorField(_errorField)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    const _account = event.target[0]?.value;
    const _password = event.target[1]?.value;

    try {
      const input = {
        account: _account,
        password: _password
      };

      const resAPI: any = await fetch("/api/member/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      }).then((res) => res.json()).catch((err) => {
        sendLog({
          action: "Login",
          page_name: "FormSignIn",
          page_url: router.asPath,
          session_key: session_key,
          status: "fail",
          error_message: JSON.stringify(err),
          information: "Login fail",
          request_json: JSON.stringify(input)
        })
      })

      setIsLoading(false);
      if (resAPI?.id === null) {
        handleOpenNotify(getValueByKey(["Message", "Error", "Login_Fail"]));
        return;
      }

      handleOpenNotify(getValueByKey(["Message", "Success", "Login"]));
      if (router.query.prevUrl) {
        router.push(router.query.prevUrl as string);
      } else {
        router.push("/");
      }
      if (watch('remember')) {
        dispatch(setUserInfo({
          userInfo: handleEncode(resAPI, SECRET_KEY)
        }));
        dispatch(clearUserTempInfo());
      } else {
        dispatch(setUserTempInfo({
          userInfo: handleEncode(resAPI, SECRET_KEY)
        }));
        dispatch(clearUserInfo());
      }

    } catch (e) {
      setIsLoading(false);
      handleOpenNotify(getValueByKey(["Message", "Error", "Had_Error"]));
    }
  }
  const handleClosedNotifyCustom = () => {
    handleClosedNotify()

  }


  return (
    // <LoadingOverlay loading={isLoading}>
    <>
      <PopupNoti isOpen={popupNotify.isOpen} handleClose={handleClosedNotifyCustom} content={popupNotify.message} />
      <form className="form" ref={formRef} onSubmit={handleSubmit}>
        <div className="form-list">
          <div className="form-it">
            <label className="form-label">{getValueByKey("Sign_In_Account")} <span className='text-error'>*</span></label>
            <div>
              <Input
                className="form-control input"
                type="text"
                required
                placeholder=""
                name='account'
                messageError={errorField.account}
              />
            </div>
          </div>
          <div className="form-it">
            <label className="form-label">{getValueByKey("Password")} <span className='text-error'>*</span></label>
            <div className="input-has-icon">
              <Input
                className="form-control input"
                type={isShowPassword ? "text" : "password"}
                placeholder=""
                required
                name='password'
                messageError={errorField.password}
              />
              <div onClick={() => setIsShowPassword(!isShowPassword)}>

                {!isShowPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
            </div>
          </div>
          <div className="checkbox">
            <Controller
              name='remember'
              control={control}
              render={({
                field: { value, onChange },
                formState: { },
              }) => (
                <input className="check checkbox-input" value={value} type="checkbox" id="checkbox-1" onChange={(e) => onChange(e.target.checked)} />
              )} />
            <label className="checkbox-label checkbox-label-custom !text-black" htmlFor="checkbox-1">
              <span className="checkbox-label-custom-sqr mt-[5px]"></span>
              {getValueByKey("keep_Login")}</label>
          </div>
          <div className="form-it"><Link className="link-forget-pass" href="/change-pass">{getValueByKey("Password_Forgot")}</Link></div>
          <div className="form-it">
            <button className="btn btn--pri w-full h-[40px]" onClick={handleCheckValueForm} type='submit'  >
              {isLoading ? <i className="fa-regular fa-circle-notch fa-spin"></i> : getValueByKey("Sign_In")}
            </button>
          </div>
        </div>

        {/*
          <div className="form-footer">
          <div className="txt-dec">Hoặc</div>
          <ul className="list-social">
            <li className="item-social">
              <a className="link-social">
                <span className="icon">
                  <Image src="/assets/images/ic-fb-logo.svg" alt="" width={50} height={50} />
                </span>
                <span className="txt">Tiếp tục với Facebook</span>
              </a>
              <a className="link-social">
                <span className="icon">
                  <Image src="/assets/images/ic-gg-logo.svg" alt="" width={50} height={50} />
                </span>
                <span className="txt">Tiếp tục với Google</span>
              </a>
            </li>
          </ul>
          <div className="acc-login-notice">Chưa có có tài khoản?
            <span className="link cursor-pointer" onClick={() => {
              document.getElementById("tabSignUp")?.click();
            }}>
              Đăng ký
            </span>
          </div>
        </div>
        */}
      </form>
    </>
    // </LoadingOverlay>
  )
}

export default FormSignIn