/* eslint-disable @next/next/no-img-element */
import useDataUser from '@/hooks/useDataUser';
import { tabConfig } from '@/utils/TabConfig';
import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect } from 'react';
import FormSignIn from './FormSignIn';
import FormSignUp from './FormSignUp';
import useContentGlobal from '@/hooks/useContentGlobal';

export const LoginPage = ({ numberTab }: { numberTab: number }) => {
  const userInfo = useDataUser();
  const router = useRouter()

  const { getValueByKey } = useContentGlobal();

  useLayoutEffect(() => {
    if (userInfo?.id !== "") {
      if (router.query.prevUrl) {
        router.push(router.query.prevUrl as string);
      } else {
        router.push("/");
      }
    }
  }, [router, userInfo?.id])

  useEffect(() => {
    tabConfig(numberTab);
  }, [numberTab])

  return (
    <section className="sec-regis">
      <div className="regis ht">
        <div className="bg"> <img className="bg-regis-des" src="/assets/images/bg-regis.jpg" alt="" /><img className="bg-regis-mobi" src="/assets/images/bg-regis-mobi.jpg" alt="" /></div>
        <div className="container">
          <div className="action-auth tabJS">
            <div className="tab-control">
              <div className="tab-control-it tabBtn" id="tabSignIn">
                <h2 className="tt">{getValueByKey("Sign_In")}</h2>
              </div>
              <div className="tab-control-it tabBtn" id="tabSignUp">
                <h2 className="tt">{getValueByKey("Sign_Up")}</h2>
              </div>
            </div>
            <div className="form-auth">
              <div className="tab-content-it form-login tabPanel">
                <FormSignIn />
              </div>
            </div>
            <div className="tab-content-it form-regis tabPanel">
              <FormSignUp />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
