import React, { useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { handleEncode, SECRET_KEY } from '@/helper/encode';
import { useAppDispatch } from '@/store/store';
import { clearUserInfo } from '@/store/userSlice';

import InputRHF from '@/components/InputRHF';
import { PopupNoti } from '@/components/screen/common/PopupNoti';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { object, ObjectSchema, ref, string } from 'yup';
import { setUserTempInfo } from '@/store/userTemp';
import { sendLog } from '@/services/sendLog';
import useSavaSessionKey from '@/hooks/useSavaSessionKey';
import { Checkbox, Collapse } from 'antd';
import { useQuery } from 'react-query';
import { account as AccountService, cmsBlock } from '@/services';
import useContentGlobal from '@/hooks/useContentGlobal';
import moment from 'moment';

type Props = {}

type RegisterForm = {
  email: string
  password: string
  confirmPassword: string
  phone: string
  name: string
  username: string
  identification: string
  birthday: string
}

const FormSignUp: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { session_key } = useSavaSessionKey();

  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();

  const formRef = useRef<HTMLFormElement | any>(null);

  const router = useRouter();
  const dispatch = useAppDispatch();

  const { getValueByKey } = useContentGlobal();

  const phoneRegExp =
    /^(84|0[3-9]|0[1-2][0-9]|0[5-8][0-9]|09)[0-9]{8}$/

  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/

  const messageEmpty = getValueByKey(["Message", "Error", "Empty_Field"]);
  const messageSpace = getValueByKey(["Message", "Error", "Have_Spaces"]);
  const messageLengthPass = getValueByKey(["Message", "Error", "Password_Length"]);

  const schema: ObjectSchema<RegisterForm> = object().shape({
    email: string().required(messageEmpty).email(getValueByKey(["Message", "Error", "Email"])),
    name: string().required(messageEmpty),
    password: string()
      .test('spaces', messageSpace, (value) => {
        const arrayCharacterVietnamese = ' '?.split('')
        const checkStringVietnamese = arrayCharacterVietnamese.find(
          (textCharacter) => value?.includes(textCharacter)
        )
        return !checkStringVietnamese
      })
      .min(8, messageLengthPass)
      .required(messageEmpty)
      .matches(passwordRegExp, getValueByKey(["Message", "Error", "Password"])),
    confirmPassword: string()
      .test('spaces', messageSpace, (value) => {
        const arrayCharacterVietnamese = ' '?.split('')
        const checkStringVietnamese = arrayCharacterVietnamese.find(
          (textCharacter) => value?.includes(textCharacter)
        )
        return !checkStringVietnamese
      })
      .min(8, messageLengthPass)
      .required(messageEmpty)
      .oneOf([ref('password')], getValueByKey(["Message", "Error", "Password_Confirm"])),
    phone: string().required(messageEmpty).matches(phoneRegExp, getValueByKey(["Message", "Error", "Phone_Number"])),
    birthday: string().required(getValueByKey(["Message", "Error", "Empty_Birthday"])),
    username: string()
      .test('spaces', messageSpace, (value) => {
        const arrayCharacterVietnamese = ' '?.split('')
        const checkStringVietnamese = arrayCharacterVietnamese.find(
          (textCharacter) => value?.includes(textCharacter)
        )
        return !checkStringVietnamese
      })
      .required(messageEmpty),
    identification: string().required(messageEmpty),
  })
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const CODE_RESPONSE_CREATE = [
    getValueByKey(["Message", "Error", "Had_Error"]),
    getValueByKey(["Message", "Success", "Sign_Up"]),
    getValueByKey(["Message", "Error", "Had_Username"]),
    getValueByKey(["Message", "Error", "Had_Email"]),
    getValueByKey(["Message", "Error", "Had_Phonenumber"]),
    getValueByKey(["Message", "Error", "Had_CCCD"]),
  ];

  const handleCallApiLogin = async (account: string, password: string) => {
    try {
      const resAPI = await fetch("/api/member/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account: account,
          password: password
        }),
      }).then(res => res.json()).catch((err) => {
        sendLog({
          action: "login_member",
          page_name: "FormSignUpPage",
          page_url: router.asPath,
          session_key: "",
          status: "fail",
          error_message: JSON.stringify(err),
          information: "Login member fail",
          request_json: JSON.stringify({
            account: account,
            password: password
          })
        })
      })
      handleOpenNotify(getValueByKey(["Message", "Success", "Sign_Up"]));

      dispatch(setUserTempInfo({
        userInfo: handleEncode(resAPI, SECRET_KEY)
      }));
      dispatch(clearUserInfo());
      router.back();
    } catch (error) { }
  }

  const handleOnSubmit = async (event: any) => {
    setIsLoading(true);
    const formattedBirthday = moment(event.birthday).format("DD/MM/YYYY");
    // console.log("birthday", dayjs(event.birthday).format("DD/MM/YYYY"),);

    try {
      const input = {
        name: event?.name,
        email: event?.email,
        phone: event?.phone,
        account: event?.username,
        password: event?.password,
        identification: event?.identification,
        birthday: formattedBirthday,

        image: "",
        gender: "",
        address: ""
      };

      const resAPI: any = await fetch("/api/member/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      }).then((res) => res.json()).catch((err) => {
        sendLog({
          action: "Create member",
          page_name: "FormSignUpPage",
          page_url: router.asPath,
          session_key: session_key,
          status: "fail",
          error_message: JSON.stringify(err),
          information: "Create member fail",
          request_json: JSON.stringify(input)
        })
      })
      const res = await AccountService.createMemberMagento(input) as Record<string, any>
      setIsLoading(false);

      if (resAPI?.message === '1' && res?.createMember?.message) {
        event?.target?.reset();
        handleOpenNotify(CODE_RESPONSE_CREATE?.[resAPI?.message] ?? getValueByKey(["Message", "Success", "Sign_Up"]));
        return handleCallApiLogin(event.username, event.password);
      }

      return handleOpenNotify(CODE_RESPONSE_CREATE?.[resAPI?.message] ?? CODE_RESPONSE_CREATE[0] as string);
    } catch (error) {
      setIsLoading(false);
      return handleOpenNotify(CODE_RESPONSE_CREATE[0] as string);
    }
  }

  const { data: dataContent } = useQuery(
    ["cmsBlockPolicy"],
    () => cmsBlock.getCmsBlock({ "identifiers": ["chinh-sach-bao-mat"] }).then((res: any) => {
      return res?.cmsBlocks?.items
    }))

  return (
    // <LoadingOverlay loading={isLoading}>
    <>
      <PopupNoti isOpen={popupNotify.isOpen} handleClose={handleClosedNotify} content={popupNotify.message} />
      <form
        className="form"
        ref={formRef}
        onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="form-list">

          <InputRHF control={control} name='name' label={getValueByKey("Full_Name")} required placeholder={getValueByKey("Name")} />

          <div className="form-it mb-[10px]">
            <label className="form-label">{getValueByKey("Birthday")} <span className='text-error'>*</span></label>
            <div className='input-has-icon'>
              <Controller control={control} name='birthday' render={({ field, formState: { errors } }) => {
                return (<>
                  <input {...field} className="dateTimeInput input-date-custom input" type="date" />
                  <i className="icon fa-solid fa-calendar-days pointer-events-none !cursor-pointer">
                  </i>
                  {!!errors?.birthday ? (
                    <div className="absolute mt-1">
                      <div className={clsx(errors?.birthday ? 'flex items-center gap-2 overflow-visible' : 'hidden')}>
                        <p className="text-error text-[12px] mt-[2px]">{errors?.birthday?.message || 'Vui lòng nhập trường này'}</p>
                      </div>
                    </div>
                  ) : null}
                </>)
              }} />
            </div>
          </div>

          <InputRHF control={control} name='phone' label={getValueByKey("Phone_Number")} required placeholder={getValueByKey("Phone_Number")} />

          <InputRHF control={control} name='username' label={getValueByKey("Username")} required placeholder={getValueByKey("Username")} />

          <InputRHF control={control} name='identification' label='CCCD/CMND' required placeholder={getValueByKey("CCCD")} />

          <InputRHF control={control} name='email' label='Email' required placeholder={getValueByKey("Email_Placeholder")} />

          <InputRHF control={control} name='password' label={getValueByKey("Password")} type='password' required placeholder={getValueByKey("Password")} />

          <InputRHF control={control} name='confirmPassword' label={getValueByKey("Password_Confirm")} type='password' required placeholder={getValueByKey("Password_Confirm")} />

          {/* content */}
          <Collapse bordered={false} className='!bg-[#F8FAFC]'>
            <Collapse.Panel key={"1"} header={getValueByKey("Privacy_Policy") ?? "Chính sách bảo mật"} className=''>
              <div dangerouslySetInnerHTML={{ __html: dataContent?.[0]?.content }} className='h-[20rem] overflow-y-scroll'></div>
            </Collapse.Panel>
          </Collapse>

          <div className='flex gap-4 items-start'>
            <div className='mr-[1rem]'>
              <Checkbox />
            </div>
            <p className='!ml-2'>{getValueByKey("Agree_Terms")}</p>
          </div>

          <div className="form-it">
            <button className="btn btn--pri w-full h-[40px]" type='submit' >{isLoading ? <i className="fa-regular fa-circle-notch fa-spin"></i> : getValueByKey("Sign_Up")}</button>
          </div>
          {/* <div className="form-it"><a className="btn btn--pri" href="">ĐĂNG KÍ</a></div> */}
          <div className="form-it">
            <div className="acc-login-notice">{getValueByKey("Have_Account")} {' '}
              <span className="link cursor-pointer" onClick={() => {
                document.getElementById("tabSignIn")?.click();
                window.scrollTo(0, 0);
              }}>
                {getValueByKey("Sign_In")}
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
    // </LoadingOverlay>
  )
}

export default FormSignUp
