import $ from 'jquery'

export function tabConfig(index?: number) {

  const indexTab = index || 0
	let tab = document.querySelectorAll('.tabJS')
	if (tab) {
		tab.forEach((t) => {
			let tBtn: any = t.querySelectorAll('.tabBtn')
			let tPanel: any = t.querySelectorAll('.tabPanel')
			$(tPanel[indexTab]).slideDown()

			// for tab
			if (tBtn.length !== 0 && tPanel.length === tBtn.length) {
				tBtn[indexTab]?.classList.add('active')
				tPanel[indexTab]?.classList.add('open')

				for (let i = 0; i < tBtn.length; i++) {
					tBtn[i].onclick = (e: any) => {
						e.preventDefault()
						for (let a = 0; a < tBtn.length; a++) {
							tBtn[a].classList.remove('active')
							tPanel[a].classList.remove('open')
							$(tPanel[a]).slideUp()
						}
						tBtn[i].classList.add('active')
						tPanel[i].classList.add('open')
						$(tPanel[i]).slideDown()
					}
				}
			}
		})
	}
}
